import React, { useState, useEffect } from 'react';
import { auth, db } from './Firebase';
import 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container, FormControlLabel, Radio, Typography, TextField, Grid2, Alert, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, fetchSignInMethodsForEmail } from 'firebase/auth';

const Trip = () => {
    const [userData, setUserData] = useState(null);
    const [isAttending, setIsAttending] = useState(null);
    const [isAvecAttending, setIsAvecAttending] = useState(null);
    const [allergies, setAllergies] = useState('');
    const [avecAllergies, setAvecAllergies] = useState('');
    const [email, setEmail] = useState('');
    const [foodChoice, setFoodChoice] = useState('');
    const [avecFoodChoice, setAvecFoodChoice] = useState('');
    const [drinkChoice, setDrinkChoice] = useState('');
    const [avecDrinkChoice, setAvecDrinkChoice] = useState('');
    const [freeWord, setFreeWord] = useState('');
    const { code } = useParams();
    const [showAlert, setShowAlert] = useState({ open: false, message: '', severity: 'success' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersSnapshot = await db.collection('invites').get();
                let foundUser = null;

                usersSnapshot.forEach(doc => {
                    if (doc.id.slice(-4) === code) {
                        foundUser = doc.data();
                    }
                });
                if (!foundUser) {
                    console.log('New user coming in')
                } else {
                    setUserData(foundUser);
                }
            } catch (error) {
                console.error('Error fetching or creating user:', error);
            }
        };

        fetchData();
    }, [code]);

    const handleRSVPChange = (event) => {
        setIsAttending(event.target.value);
    };

    const handleAvecRSVPChange = (event) => {
        setIsAvecAttending(event.target.value);
    };

    const handleAllergiesChange = (event) => {
        setAllergies(event.target.value);
    };

    const handleAvecAllergiesChange = (event) => {
        setAvecAllergies(event.target.value);
    };

    const handleFoodChoiceChange = (event) => {
        setFoodChoice(event.target.value);
    };

    const handleAvecFoodChoiceChange = (event) => {
        setAvecFoodChoice(event.target.value);
    };

    const handleDrinkChoiceChange = (event) => {
        setDrinkChoice(event.target.value);
    };
    const handleAvecDrinkChoiceChange = (event) => {
        setAvecDrinkChoice(event.target.value);
    };
    const handleFreeWord = (event) => {
        setFreeWord(event.target.value);
    };

    const submitRSVP = async () => {
        if (isAttending === null) {
            setShowAlert({ open: true, message: 'Täytäthän kaikki tarvittavat kohdat.', severity: 'error' });
            return;
        }

        setIsSubmitting(true); //Start loading
        try {
            let userId = null;

            // Only do user creation and email stuff if they are attending
            if (isAttending === 'yes') {
                 if (!email) {
                    setShowAlert({ open: true, message: 'Sähköposti on pakollinen, kun osallistut.', severity: 'error' });
                    setIsSubmitting(false);
                    return;
                 }
                
                const signInMethods = await fetchSignInMethodsForEmail(auth, email);

                if (signInMethods.length === 0) {
                    // Email doesn't exist, create a new user
                    const userCredential = await createUserWithEmailAndPassword(auth, email, 'defaultPassword');
                    userId = userCredential.user.uid;

                    const newUser = {
                        email,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        avecName: userData.avecName
                    };
                    await db.collection('users').doc(userId).set(newUser);

                    await sendPasswordResetEmail(auth, email);
                    console.log('Password email sent successfully.');
                } else {
                    // Email already exists, just send password reset email
                    console.log("user exists, sending password reset email");
                    await sendPasswordResetEmail(auth, email);
                    console.log('Password email sent successfully.');

                    userId = auth.currentUser?.uid;
                    if (!userId) {
                        console.error('user does not exists.');
                        setShowAlert({ open: true, message: `Käyttäjätunnusta ei saatu luettua!`, severity: 'error' });
                        setIsSubmitting(false);
                        return;
                    }
                    const newUser = {
                        email,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                    };

                    if (userData.avec && userData.avecName) {
                        newUser.avecName = userData.avecName;
                    }
                    await db.collection('users').doc(userId).set(newUser);
                }
            }

            // Create the response document
            const response = {
                userId: userId, // Will be null if not attending
                firstName: userData.firstName,
                lastName: userData.lastName,
                attending: isAttending,
            };

            if (isAttending === 'yes') {
                response.allergies = allergies;
                response.foodChoice = foodChoice;
                response.drinkChoice = drinkChoice;
                response.freeWord = freeWord;
            }

            if (userData.avec) {
                response.avecName = userData.avecName;
                response.avecAttending = isAvecAttending;
                if (isAvecAttending === 'yes') {
                    response.avecAllergies = avecAllergies;
                    response.avecFoodChoice = avecFoodChoice;
                    response.avecDrinkChoice = avecDrinkChoice;
                }
            }

            await db.collection('responses').add(response);
            setShowAlert({ open: true, message: 'Kiitos vastauksestasi!', severity: 'success' });
            navigate(`/editrsvp/`);

        } catch (error) {
            console.error('Error creating user or submitting RSVP:', error);
            setShowAlert({ open: true, message: `Vastauksen lähettäminen epäonnistui. Yritä kohta uudelleen tai ota yhteyttä! ${error}`, severity: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <Container>
            <>
                {showAlert.open && (
                    <Alert onClose={() => setShowAlert({ ...showAlert, open: false })} severity={showAlert.severity}>
                        {showAlert.message}
                    </Alert>
                )}
                <div className="wedding-rsvp">
                    {userData ? (
                        <>
                            <Typography variant="h3" component="h3">
                                Tervetuloa matkalle kanssamme!
                            </Typography>
                            <br />
                            <Typography paragraph>
                                Dear {userData.firstName} {userData.lastName}{userData.avec ? (<> ja {userData.avecName ? userData.avecName : <>avec</>}</>) : (<></>)},
                            </Typography>
                            <Typography paragraph>
                                Kutsumme {userData.avecName ? (<>teidät </>) : (<>sinut </>)}
                                mukaan meidän yhteiselle matkalle 24.5.2025 Vanhasta Tuomiokirkosta Scandic Hotel Grand Centraliin häidemme merkeissä.
                            </Typography>
                            <Typography paragraph>
                                Varaathan matkasi viimeistään 30.4.2025.
                            </Typography>
                            <Typography paragraph>
                                Pääasiassa juhlat juhlitaan aikuisten kesken, mutta lähipiirissämme on pieniä lapsia joita ei voi jättää kotiin tai hoitoon. Myös Tuomaksen lapset ovat mukana juhlassa.
                                Jos teidän tarvitsee ottaa lapsi/lapset mukaan, niin otattehan meihin yhteyttä asian tiimoilta, niin tiedämme varautua asiaan.
                            </Typography>
                            <Typography paragraph>
                                Ilmoittautumisen jälkeen pääset tälle sivulle vielä takaisinkin samalla koodilla.
                            </Typography>
                            <br />
                            <Grid2 container spacing={2}>
                                <Grid2 item xs={12}>
                                    <Typography variant="h5" component="h5">
                                        Osallistuminen, {userData.firstName} {userData.lastName}
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                id="attending-yes"
                                                name="attending"
                                                value="yes"
                                                onChange={handleRSVPChange}
                                                checked={isAttending === 'yes'}
                                            />
                                        }
                                        label="Kyllä, osallistun"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                id="attending-no"
                                                name="attending"
                                                value="no"
                                                onChange={handleRSVPChange}
                                                checked={isAttending === 'no'}
                                            />
                                        }
                                        label="En pääse osallistumaan"
                                    />
                                </Grid2>
                            </Grid2>
                            {isAttending === 'yes' && (
                                <>
                                    <Grid2 item xs={12}>
                                        <TextField
                                            id="email"
                                            label="Sähköposti"
                                            type="email"
                                            fullWidth
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Grid2>
                                    <br />
                                    <Grid2 item xs={12}>
                                        <TextField
                                            id="allergies"
                                            label="Allergiat ja ruokarajoitteet"
                                            multiline
                                            fullWidth
                                            value={allergies}
                                            onChange={handleAllergiesChange}
                                        />
                                    </Grid2>
                                    <br />
                                    <Grid2 item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="food-choice-label">Ruokavalinta</InputLabel>
                                            <Select
                                                labelId="food-choice-label"
                                                id="food-choice"
                                                value={foodChoice}
                                                label="Ruokavalinta"
                                                onChange={handleFoodChoiceChange}
                                                fullWidth
                                            >
                                                <MenuItem value="meat">Lihavaihtoehto</MenuItem>
                                                <MenuItem value="fish">Kalavaihtoehto</MenuItem>
                                                <MenuItem value="vegetarian">Kasvisvaihtoehto</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                    <br />
                                    <Grid2 item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="drink-choice-label">Juomavalinta</InputLabel>
                                            <Select
                                                labelId="drink-choice-label"
                                                id="drink-choice"
                                                value={drinkChoice}
                                                label="Juomavalinta"
                                                onChange={handleDrinkChoiceChange}
                                                fullWidth
                                            >
                                                <MenuItem value="nonalco">Alkoholiton</MenuItem>
                                                <MenuItem value="alcoholic">Alkoholillinen</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                    <br />
                                    <Grid2 item xs={12}>
                                        <TextField
                                            id="freeword"
                                            label="Vapaa sana"
                                            multiline
                                            fullWidth
                                            value={freeWord}
                                            onChange={handleFreeWord}
                                        />
                                    </Grid2>
                                </>
                            )}
                            {userData.avec === true && (
                                <><br />
                                    <Grid2 container spacing={2}>
                                        <Grid2 item xs={12}>
                                            <Typography variant="h5" component="h5">
                                                Osallistuminen, {userData.avecName}
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        id="attending-yes"
                                                        name="avecAttending"
                                                        value="yes"
                                                        onChange={handleAvecRSVPChange}
                                                        checked={isAvecAttending === 'yes'}
                                                    />
                                                }
                                                label="Kyllä, osallistuu"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        id="attending-no"
                                                        name="avecAttending"
                                                        value="no"
                                                        onChange={handleAvecRSVPChange}
                                                        checked={isAvecAttending === 'no'}
                                                    />
                                                }
                                                label="Ei pääse osallistumaan"
                                            />
                                        </Grid2>
                                    </Grid2>
                                </>
                            )}
                            {isAvecAttending === 'yes' && (
                                <>
                                    <Grid2 item xs={12}>
                                        <TextField
                                            id="avec-allergies"
                                            label="Allergiat ja ruokarajoitteet"
                                            fullWidth
                                            value={avecAllergies}
                                            onChange={handleAvecAllergiesChange}
                                        />
                                    </Grid2>
                                    <br />
                                    <Grid2 item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="avec-food-choice-label">Ruokavalinta</InputLabel>
                                            <Select
                                                labelId="avec-food-choice-label"
                                                id="avec-food-choice"
                                                value={avecFoodChoice}
                                                label="Ruokavalinta"
                                                onChange={handleAvecFoodChoiceChange}
                                                fullWidth
                                            >
                                                <MenuItem value="meat">Lihavaihtoehto</MenuItem>
                                                <MenuItem value="fish">Kalavaihtoehto</MenuItem>
                                                <MenuItem value="vegetarian">Kasvisvaihtoehto</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                    <br />
                                    <Grid2 item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="avec-drink-choice-label">Juomavalinta</InputLabel>
                                            <Select
                                                labelId="avec-drink-choice-label"
                                                id="avec-drink-choice"
                                                value={avecDrinkChoice}
                                                label="Juomavalinta"
                                                onChange={handleAvecDrinkChoiceChange}
                                                fullWidth
                                            >
                                                <MenuItem value="nonalco">Alkoholiton</MenuItem>
                                                <MenuItem value="alcoholic">Alkoholillinen</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                </>
                            )}

                            {isAttending === 'no' && (
                                <>
                                    <br />
                                    <Typography paragraph>Harmi ettette pääse. yhyy :(</Typography>
                                    {/* <p> Haluatko osallistua kuitenkin häärahastoon? Se onnistuu helposti esimerkiksi <Link to="https://qr.mobilepay.fi/box/220d21f3-6539-4f57-b672-feaadaa9670b/pay-in">MobilePayllä</Link>!</p>
                                        <p>Häärahasto on menossa lyhentämättömänä juhliin ja jos jotain jää, niin häämatkaan.</p> */}
                                </>
                            )}
                            <br />
                            <Button variant="contained" color="secondary" fullWidth onClick={submitRSVP} disabled={isAttending === null || isSubmitting}>
                                {isSubmitting ? 'Lähetetään...' : 'Lähetä vastaus'}
                            </Button>
                            <br />
                            <br />
                            <br />
                            <br />
                        </>
                    ) : (
                        <p>Lataillaan...</p>
                    )}
                </div>
            </>
        </Container >
    );
};

export default Trip;