import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Trip from './components/Trip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import firebase from './components/Firebase';
import 'firebase/compat/analytics';
import './App.css'
import LoginPage from './components/Login';
import InviteList from './components/InviteList';
import { auth } from './components/Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Arrival from './components/Arrival';
import Accomodation from './components/Accomodation';
import Timetable from './components/Timetable';
import Giftlist from './components/GiftList';
import AddGift from './components/AddGift';
import Contact from './components/Contact';
import ResponsesPage from './components/Responses';
import EditRsvp from './components/EditRsvp';

const theme = createTheme({
  palette: {
    primary: {
      main: "#facad3"
    },
    secondary: {
      main: "#eb839b",
    }
  },
  typography: {
    "fontFamily": `"Josefin Sans", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    h1: {
      fontFamily: '"Prata", sans-serif', // Font for h1
    },
    h2: {
      fontFamily: '"Prata", sans-serif', // Font for h2
    },
    h3: {
      fontFamily: '"Prata", sans-serif',  // Font for h3
    },
  },
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserId(user.uid);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header isLoggedIn={isLoggedIn} />
        <br />
        <br />
        <Routes>
          {isLoggedIn ? (
            <>
              <Route path="/" element={<Hero />} />
              <Route path="/trip/:code" element={<Trip />} />
              <Route path="/edit" element={<EditRsvp />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/saapuminen" element={<Arrival />} />
              <Route path="/majoitus" element={<Accomodation />} />
              <Route path="/aikataulu" element={<Timetable />} />
              <Route path="/lahjalista" element={<Giftlist />} />
              <Route path="/yhteys" element={<Contact />} />
              {userId === 'LPEQmKIxYbTrnMZatB4imGIrzqv1' || userId === '00aBFhovRZhLjIyksya2zHy3oIh1' ? (
                <>
                  <Route path='/responses' element={<ResponsesPage />} />'
                  <Route path="/inviteList" element={<InviteList />} />
                  <Route path="/addgift" element={<AddGift />} />
                </>
              ) : null}
            </>
          ) : (
            <>
              <Route path="/" element={<Hero />} />
              <Route path="/trip/:code" element={<Trip />} />
              <Route path="/login" element={<LoginPage />} />
            </>
          )}
        </Routes>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {isLoggedIn ? (
          <Footer />
        ) : null}
      </Router>
    </ThemeProvider>
  );
}
firebase.analytics();

export default App;