import { Typography, Container, Grid2 } from '@mui/material';

const Contact = () => {
  return (
    <Container>
      <Typography variant="h3" component="h1" gutterBottom>
        Ota yhteyttä
      </Typography>

      <Grid2 container spacing={2}>
        <Grid2 item xs={12} md={6}>
          <Typography paragraph>
            Voit ottaa yhteyttä meihin, jos tulee mitään kysyttävää.
          </Typography>
          <Typography paragraph>
            Tuomas Savela<br/>
            040-1946262 <br/>
            tuomas@haissa.fi
          </Typography>
          <Typography paragraph>
            Pia Blomstén<br/>
            0400-388249<br />
            pia@haissa.fi
          </Typography>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default Contact;