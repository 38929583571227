import React, { useState } from 'react';
import { Button, TextField, Typography, Grid2, Box, Container, Alert } from '@mui/material';
import { auth } from './Firebase';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [lastName, setLastName] = useState('');
  const [loginMethod, setLoginMethod] = useState('email');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError(null);
    try {
      if (loginMethod === 'email') {
        auth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user = userCredential.user
          console.log(user)
        })
        navigate("/");
      } else {
        const response = await fetch('/api/loginWithInvite', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ inviteCode, lastName }),
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({ error: "Verkkovirhe, yritäpä myöhemmin uusiks." }));
          throw new Error(errorData.error || "Invite code login failed.");
        }

        const { customToken } = await response.json();
        await auth.signInWithCustomToken(customToken);
        navigate('/');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    }
  };



  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Grid2 container spacing={2}>
          <Grid2 item xs={12} md={6}>
            <Typography variant="h3">Kirjaudu</Typography>

            <div style={{ display: loginMethod === 'email' ? 'block' : 'none' }}>
              <TextField label="Sähköposti" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="normal" />
              <TextField label="Salasana" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth margin="normal" />
            </div>
            <div style={{ display: loginMethod === 'invite' ? 'block' : 'none' }}>
              <TextField label="Koodi" value={inviteCode} onChange={(e) => setInviteCode(e.target.value)} fullWidth margin="normal" />
              <TextField label="Sukunimi" value={lastName} onChange={(e) => setLastName(e.target.value)} fullWidth margin="normal" />
            </div>

            <Button variant="contained" color="primary" onClick={handleLogin}>
              Kirjaudu
            </Button>
            <Button onClick={() => setLoginMethod(loginMethod === 'email' ? 'invite' : 'email')}>
              {loginMethod === 'email' ? 'Kirjaudu kutsukoodilla' : 'Kirjaudu sähköpostilla'}
            </Button>

          </Grid2>
        </Grid2>
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
    </Container>
  );
}

export default LoginPage;