import React, { useState, useEffect } from 'react';
import { db } from './Firebase';
import { collection, getDocs } from 'firebase/firestore';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography} from '@mui/material';
import * as XLSX from 'xlsx';

const ResponsesPage = () => {
    const [responses, setResponses] = useState([]);
    const [notJoining, setNotJoining] = useState([]);

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const responsesSnapshot = await getDocs(collection(db, 'responses'));
                const responsesData = responsesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                const filteredResponses = responsesData.filter(response => response.attending === 'yes');
                const otherResponses = responsesData.filter(response => response.attending === 'no');

                setResponses(filteredResponses);
                setNotJoining(otherResponses);
            } catch (error) {
                console.error("Error fetching responses:", error);
            }
        };

        fetchResponses();
    }, []);

    const columns = [
        { field: 'firstName', headerName: 'Etunimi', width: 150 },
        { field: 'lastName', headerName: 'Sukunimi', width: 150 },
        { field: 'avecName', headerName: 'Avecin nimi', width: 150 },
        { field: 'attending', headerName: 'Osallistuu', width: 150 },
        { field: 'allergies', headerName: 'Allergiat', width: 150 },
        { field: 'avecAllergies', headerName: "Avecin allergiat", width: 150 },
        { field: 'foodChoice', headerName: 'Ruokavalinta', width: 150 },
        { field: 'avecFoodChoice', headerName: "Avecin ruokavalinta", width: 150 },
        { field: 'drinkChoice', headerName: 'Juomavalinta', width: 150 },
        { field: 'avecDrinkChoice', headerName: "Avecin juomavalinta", width: 150 },
        { field: 'freeWord', headerName: 'Vapaa sana', width: 150 },
    ];

    const otherColumns = [
        { field: 'firstName', headerName: 'Etunimi', width: 150 },
        { field: 'lastName', headerName: 'Sukunimi', width: 150 },
        { field: 'attending', headerName: 'Osallistuu', width: 150 },
    ];

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(responses);
        const otherWorksheet = XLSX.utils.json_to_sheet(notJoining);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Osallistuvat");
        XLSX.utils.book_append_sheet(workbook, otherWorksheet, "Ei-osallistuvat");
        XLSX.writeFile(workbook, "vastaukset.xlsx");
    };

    return (
        <>
            <Typography variant="h4" gutterBottom>Osallistuvat</Typography>
            <div style={{ height: 800, width: '100%' }}>
                <DataGrid rows={responses} columns={columns} pageSize={20} rowsPerPageOptions={[20]} />
            </div>

            <Typography variant="h4" gutterBottom>Ei-osallistuvat</Typography>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid rows={notJoining} columns={otherColumns} pageSize={20} rowsPerPageOptions={[20]} />
            </div>

            <Button variant="contained" onClick={handleExportToExcel} style={{ marginTop: '1rem' }}>
                Lataa Pian lempparitiedostomuodossa
            </Button> 
        </>
    );
};

export default ResponsesPage;