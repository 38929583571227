import { Typography, Container, Grid2 } from '@mui/material';

const Arrival = () => {
  return (
    <Container>
      <Typography variant="h3" component="h1" gutterBottom>
        Saapuminen
      </Typography>

      <Grid2 container spacing={2}>
        <Grid2 item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Hääpaikka
          </Typography>
          <Typography paragraph>
            Vihkiminen tapahtuu Vanhassa kirkossa osoitteessa Lönnrotinkatu 6, Helsinki.
          </Typography>
          <Typography paragraph>
            Hääjuhla pidetään Scandic Hotel Grand Centralissa osoitteessa Vilhonkatu 13, Helsinki.
          </Typography>
        </Grid2>
        <Grid2 item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Kulkuyhteydet
          </Typography>
          <Typography paragraph>
            Molempiin paikkoihin on helppoa päästä julkisilla liikennevälineillä tai kävellen. Rautatieasemalta on noin vartin kävelymatka kirkolle, ja juhlapaikka sijaitsee rautatieasemalla vanhassa VR:n pääkonttorissa.
          </Typography>
          <Typography paragraph>
            Omalla autolla saapuville suositeltavinta on pysäköidä P-Kluuviin tai P-Elieliin, myös Kaisaniemen puistossa on mahdollisesti pysäköintitilaa.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography variant="h5" gutterBottom>
            Aikataulu
          </Typography>
          <Typography paragraph>
            Vihkiminen alkaa kello 16:00.
          </Typography>
          <Typography paragraph>
            Hääjuhla alkaa kello 17:00.
          </Typography>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default Arrival;
