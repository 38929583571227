import { AppBar, Toolbar, Typography, Button, Link } from '@mui/material';
import { auth } from './Firebase';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Header = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  const allowedUsers = ['LPEQmKIxYbTrnMZatB4imGIrzqv1', '00aBFhovRZhLjIyksya2zHy3oIh1'];

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);
  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log('User logged out successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <Link href="/" style={{ textDecoration: 'none', color: 'inherit' }}>Häissä</Link>
        </Typography>
        {allowedUsers.includes(userId) && (
          <>
            <Link href="/responses" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button color="main">Vastaukset</Button>
            </Link>
            <Link href="/addgift" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button color="main">Lahjalista</Button>
            </Link>
            <Link href="/invitelist" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button color="main">Kutsut</Button>
            </Link>
          </>
        )}
        {isLoggedIn ? <>

          <Link href="/edit" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color="main">Vastauksesi</Button>
          </Link>
          <Link style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color="main" onClick={handleLogout}>
              Kirjaudu ulos
            </Button>
          </Link>
        </> : <>
          <Link href="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color="main">Kirjaudu</Button>
          </Link>
        </>}
      </Toolbar>
    </AppBar>
  );
};

export default Header;