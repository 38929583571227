import React, { useState, useEffect } from 'react';
import { auth, db } from './Firebase';
import { doc, getDocs, updateDoc, collection, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Button, Container, FormControlLabel, Radio, Typography, TextField, Grid2, Alert, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const EditRsvp = () => {
    const [response, setResponse] = useState(null);
    const [isAttending, setIsAttending] = useState(null);
    const [isAvecAttending, setIsAvecAttending] = useState(null);
    const [allergies, setAllergies] = useState('');
    const [avecAllergies, setAvecAllergies] = useState('');
    const [foodChoice, setFoodChoice] = useState('');
    const [avecFoodChoice, setAvecFoodChoice] = useState('');
    const [drinkChoice, setDrinkChoice] = useState('');
    const [avecDrinkChoice, setAvecDrinkChoice] = useState('');
    const [freeWord, setFreeWord] = useState('');
    const [showAlert, setShowAlert] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Fetching the userId
    useEffect(() => {
        const fetchUserId = async () => {
            const unsubscribe = auth.onAuthStateChanged(async (user) => {
                if (user) {
                    const userUid = user.uid;
                    await fetchResponse(userUid);

                }
            });
            return () => unsubscribe();
        }
        fetchUserId();
    }, []);

    const fetchResponse = async (userId) => {
        try {
            setLoading(true);
            // Query the 'responses' collection for a document where 'userId' field matches the current user's ID.
            const q = query(collection(db, "responses"), where("userId", "==", userId));

            // Execute the query.
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                // get the first document that matches the query.
                const doc = querySnapshot.docs[0];

                setResponse({ id: doc.id, ...doc.data() });
                setIsAttending(doc.data().attending);
                setAllergies(doc.data().allergies || '');
                setFoodChoice(doc.data().foodChoice || '');
                setDrinkChoice(doc.data().drinkChoice || '');
                setFreeWord(doc.data().freeWord || '');
                if (doc.data().avecAttending) {
                    setIsAvecAttending(doc.data().avecAttending);
                    setAvecAllergies(doc.data().avecAllergies || '');
                    setAvecFoodChoice(doc.data().avecFoodChoice || '');
                    setAvecDrinkChoice(doc.data().avecDrinkChoice || '');
                }
            } else {
                console.error("no response found for this user id");
                setShowAlert({ open: true, message: `Vastaustasi ei löytynyt!`, severity: 'error' });
            }

        } catch (error) {
            console.error('Error fetching response:', error);
            setShowAlert({ open: true, message: `Vastauksen hakemisessa tapahtui virhe. Yritä kohta uudelleen tai ota yhteyttä! ${error}`, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleRSVPChange = (event) => {
        setIsAttending(event.target.value);
    };

    const handleAvecRSVPChange = (event) => {
        setIsAvecAttending(event.target.value);
    };

    const handleAllergiesChange = (event) => {
        setAllergies(event.target.value);
    };

    const handleAvecAllergiesChange = (event) => {
        setAvecAllergies(event.target.value);
    };

    const handleFoodChoiceChange = (event) => {
        setFoodChoice(event.target.value);
    };

    const handleAvecFoodChoiceChange = (event) => {
        setAvecFoodChoice(event.target.value);
    };

    const handleDrinkChoiceChange = (event) => {
        setDrinkChoice(event.target.value);
    };
    const handleAvecDrinkChoiceChange = (event) => {
        setAvecDrinkChoice(event.target.value);
    };
    const handleFreeWord = (event) => {
        setFreeWord(event.target.value);
    };

    const submitUpdatedRSVP = async () => {
        if (isAttending === null) {
            setShowAlert({ open: true, message: 'Täytäthän kaikki tarvittavat kohdat.', severity: 'error' });
            return;
        }
        try {
            const updatedResponse = {
                attending: isAttending,
                allergies,
                foodChoice,
                drinkChoice,
                freeWord,
            };
            if (response.avecName) {
                updatedResponse.avecAttending = isAvecAttending;
                updatedResponse.avecAllergies = avecAllergies;
                updatedResponse.avecFoodChoice = avecFoodChoice;
                updatedResponse.avecDrinkChoice = avecDrinkChoice;
            }
            await updateDoc(doc(db, 'responses', response.id), updatedResponse);
            setShowAlert({ open: true, message: 'Vastauksesi on päivitetty!', severity: 'success' });
            navigate('/');
        } catch (error) {
            console.error('Error updating response:', error);
            setShowAlert({ open: true, message: `Vastauksen päivittäminen epäonnistui. Yritä kohta uudelleen tai ota yhteyttä! ${error}`, severity: 'error' });
        }
    };

    if (loading) {
        return <Container><Typography>Ladataan...</Typography></Container>;
    }

    if (!response) {
        return <Container><Typography>Ei vastausta löydetty.</Typography></Container>;
    }

    return (
        <Container>
            <>
                {showAlert.open && (
                    <Alert onClose={() => setShowAlert({ ...showAlert, open: false })} severity={showAlert.severity}>
                        {showAlert.message}
                    </Alert>
                )}
                <div className="wedding-rsvp">
                    <>
                        <Typography variant="h3" component="h3">
                            Muokkaa vastaustasi
                        </Typography>
                        <br />
                        <Typography paragraph>
                            {response.firstName} {response.lastName} {response.avecName ? (<> ja {response.avecName}</>) : (<></>)}
                        </Typography>
                        <br />
                        <Grid2 container spacing={2}>
                            <Grid2 item xs={12}>
                                <Typography variant="h5" component="h5">
                                    Osallistuminen, {response.firstName} {response.lastName}
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            id="attending-yes"
                                            name="attending"
                                            value="yes"
                                            onChange={handleRSVPChange}
                                            checked={isAttending === 'yes'}
                                        />
                                    }
                                    label="Kyllä, osallistun"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            id="attending-no"
                                            name="attending"
                                            value="no"
                                            onChange={handleRSVPChange}
                                            checked={isAttending === 'no'}
                                        />
                                    }
                                    label="En pääse osallistumaan"
                                />
                            </Grid2>
                        </Grid2>
                        {isAttending === 'yes' && (
                            <>
                                <br />
                                <Grid2 item xs={12}>
                                    <TextField
                                        id="allergies"
                                        label="Allergiat ja ruokarajoitteet"
                                        multiline
                                        fullWidth
                                        value={allergies}
                                        onChange={handleAllergiesChange}
                                    />
                                </Grid2>
                                <br />
                                <Grid2 item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="food-choice-label">Ruokavalinta</InputLabel>
                                        <Select
                                            labelId="food-choice-label"
                                            id="food-choice"
                                            value={foodChoice}
                                            label="Ruokavalinta"
                                            onChange={handleFoodChoiceChange}
                                            fullWidth
                                        >
                                            <MenuItem value="meat">Lihavaihtoehto</MenuItem>
                                            <MenuItem value="fish">Kalavaihtoehto</MenuItem>
                                            <MenuItem value="vegetarian">Kasvisvaihtoehto</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <br />
                                <Grid2 item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="drink-choice-label">Juomavalinta</InputLabel>
                                        <Select
                                            labelId="drink-choice-label"
                                            id="drink-choice"
                                            value={drinkChoice}
                                            label="Juomavalinta"
                                            onChange={handleDrinkChoiceChange}
                                            fullWidth
                                        >
                                            <MenuItem value="nonalco">Alkoholiton</MenuItem>
                                            <MenuItem value="alcoholic">Alkoholillinen</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <br />
                                <Grid2 item xs={12}>
                                    <TextField
                                        id="freeword"
                                        label="Vapaa sana"
                                        multiline
                                        fullWidth
                                        value={freeWord}
                                        onChange={handleFreeWord}
                                    />
                                </Grid2>
                            </>
                        )}
                        {response.avecName && (
                            <><br />
                                <Grid2 container spacing={2}>
                                    <Grid2 item xs={12}>
                                        <Typography variant="h5" component="h5">
                                            Osallistuminen, {response.avecName}
                                        </Typography>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    id="attending-yes"
                                                    name="avecAttending"
                                                    value="yes"
                                                    onChange={handleAvecRSVPChange}
                                                    checked={isAvecAttending === 'yes'}
                                                />
                                            }
                                            label="Kyllä, osallistuu"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    id="attending-no"
                                                    name="avecAttending"
                                                    value="no"
                                                    onChange={handleAvecRSVPChange}
                                                    checked={isAvecAttending === 'no'}
                                                />
                                            }
                                            label="Ei pääse osallistumaan"
                                        />
                                    </Grid2>
                                </Grid2>
                            </>
                        )}
                        {isAvecAttending === 'yes' && (
                            <>
                                <Grid2 item xs={12}>
                                    <TextField
                                        id="avec-allergies"
                                        label="Allergiat ja ruokarajoitteet"
                                        fullWidth
                                        value={avecAllergies}
                                        onChange={handleAvecAllergiesChange}
                                    />
                                </Grid2>
                                <br />
                                <Grid2 item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="avec-food-choice-label">Ruokavalinta</InputLabel>
                                        <Select
                                            labelId="avec-food-choice-label"
                                            id="avec-food-choice"
                                            value={avecFoodChoice}
                                            label="Ruokavalinta"
                                            onChange={handleAvecFoodChoiceChange}
                                            fullWidth
                                        >
                                            <MenuItem value="meat">Lihavaihtoehto</MenuItem>
                                            <MenuItem value="fish">Kalavaihtoehto</MenuItem>
                                            <MenuItem value="vegetarian">Kasvisvaihtoehto</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <br />
                                <Grid2 item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="avec-drink-choice-label">Juomavalinta</InputLabel>
                                        <Select
                                            labelId="avec-drink-choice-label"
                                            id="avec-drink-choice"
                                            value={avecDrinkChoice}
                                            label="Juomavalinta"
                                            onChange={handleAvecDrinkChoiceChange}
                                            fullWidth
                                        >
                                            <MenuItem value="nonalco">Alkoholiton</MenuItem>
                                            <MenuItem value="alcoholic">Alkoholillinen</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid2>
                            </>
                        )}

                        {isAttending === 'no' && (
                            <>
                                <br />
                                <Typography paragraph>Harmi ettette pääse. yhyy :(</Typography>
                            </>
                        )}
                        <br />
                        <Button variant="contained" color="secondary" fullWidth onClick={submitUpdatedRSVP} disabled={isAttending === null}>
                            Päivitä vastaus
                        </Button>
                    </>
                </div>
            </>
        </Container>
    );
};

export default EditRsvp;